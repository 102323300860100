'use client'

import { AnalyticsLink } from '@components/analytics'
import { usePathname } from '@i18n/navigation'
import type { TypeBreadcrumbBlockFields } from '@lib/generated-types'
import { routeMapper, menuItemMapper } from '@lib/mappers/menu-item-mapper'
import { type MenuItem } from '@lib/types'
import { Breadcrumbs, Button, Container, Icon } from '@shc/ui'
import slugify from '@sindresorhus/slugify'

const getNavTree = (breadcrumbs: MenuItem[], currentIndex: number): string => {
  return breadcrumbs
    .slice(0, currentIndex + 1)
    .map(([item]: any) => item.name)
    .join(' > ')
    .trim()
}

const BreadcrumbBlock = ({
  breadcrumbs,
  buttonLabel,
  buttonAnchorOrExternalLink,
  buttonLink,
}: TypeBreadcrumbBlockFields) => {
  const activePathname = usePathname()
  const button = routeMapper(buttonLink, buttonAnchorOrExternalLink)

  if (!breadcrumbs?.length && !buttonLabel && !button.route) {
    return null
  }

  const parsedBreadcrumbs = breadcrumbs?.map(menuItemMapper)

  return (
    <Container
      as="nav"
      className="h-20 mx-auto flex flex-row flex-nowrap justify-between items-center"
      aria-label="breadcrumbs">
      <Breadcrumbs>
        {parsedBreadcrumbs?.map(([link], index, breadcrumbArr) => {
          const isActivePath = link.route.toLowerCase() === activePathname
          return (
            <AnalyticsLink
              key={`${slugify(link.name)}-${index}`}
              href={link.route}
              target={link.isInternal ? undefined : '_blank'}
              rel={link.isInternal ? undefined : 'noopener noreferrer'}
              aria-current={isActivePath ? 'page' : undefined}
              className="no-wrap"
              snowplow={{
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: getNavTree(breadcrumbArr, index),
                    navigation_url: link.route,
                    navigation_subject: link.name,
                    navigation_level: index + 1,
                  },
                },
                contexts: [{ name: 'section', data: { section_name: 'breadcrumb' } }],
              }}>
              {link.icon !== undefined && <Icon icon={link.icon} title={link.name} />}
              {!link.icon && link.name}
            </AnalyticsLink>
          )
        })}
      </Breadcrumbs>
      {buttonLabel && button.route && (
        <Button
          href={button.route}
          target={button.isInternal ? undefined : '_blank'}
          rel={button.isInternal ? undefined : 'noopener noreferrer'}
          variant="outlined"
          width="auto"
          size="xs">
          {buttonLabel}
        </Button>
      )}
    </Container>
  )
}

export default BreadcrumbBlock
